// src/pages/account.js
import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { redirectTo, Router } from "@reach/router";
import { login, logout, isAuthenticated, getProfile } from "../utils/auth";
import NavBar from "../components/NavBar";
import { graphql } from "gatsby";
import {
	Container,
	Grid,
	Text,
	Box,
	Card,
	Divider,
	Button,
	Embed,
	Flex,
} from "@theme-ui/components";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "@theme-ui/components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from "gatsby-link";
import { useState } from "react";
import Seo from "../components/seo";
import {
	Label,
	Input,
	Select,
	Textarea,
	Radio,
	Checkbox,
	Slider,
} from "theme-ui";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner } from "theme-ui";
import "./../components/style.css";

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
}

const Home = ({ user, posts }) => {
	const [state, setState] = React.useState({});
	const [userName, setUserName] = React.useState("");

	useEffect(() => {
		RetrieveUserName();
	}, []);
	// console.log(user);

	const RetrieveUserName = () => {
		const userID = user.sub;
		const options = {
			method: "GET",
			url: `https://ame-systems.us.auth0.com/api/v2/users/${userID}`,
			headers: {
				authorization: `Bearer ${process.env.AUTH0_APIV2KEY}`,
			},
		};

		axios.request(options).then((response) => {
			if (response.data.user_metadata)
				return setUserName(response.data.user_metadata.first_name);

			console.log("user does not have metadata");
		});
	};

	return (
		<Container>
			<Box py={4}>
				<Text py={3} as="h2">
					Courses for {userName !== "" ? userName : user.name}
				</Text>
				<Grid width={[192, null, 192]}>
					{posts.map((post) => {
						return (
							<Card
								key={post.slug}
								sx={{
									maxWidth: "100%",
									padding: 1,
									borderRadius: 2,
									border: "1px solid",
									borderColor: "primary",
								}}
							>
								<GatsbyImage image={getImage(post.frontmatter.featuredImage)} />
								<Text
									as="h3"
									p={2}
									sx={{
										color: "secondary",
									}}
								>
									{post.frontmatter.title} - {post.frontmatter.subTitle}
								</Text>
								<Text
									as="p"
									p={2}
									sx={{
										color: "text",
										fontSize: "12px",
									}}
								>
									{post.frontmatter.company}
								</Text>
								<Text
									as="p"
									p={2}
									sx={{
										color: "text",
									}}
								>
									{post.frontmatter.excerpt}
								</Text>
								<Link
									p={2}
									sx={{ color: "primary" }}
									to={`/myame/course/${post.frontmatter.courseID}`}
									as={GatsbyLink}
								>
									Start Course
								</Link>
							</Card>
						);
					})}
				</Grid>
			</Box>
		</Container>
	);
};

const Course = ({ user, post }) => {
	return (
		<Container>
			<GatsbyImage image={getImage(post.frontmatter.featuredImage)} />
			<h1>
				{post.frontmatter.title} - {post.frontmatter.subTitle}
			</h1>
			<Grid p={2} columns={[1, 1, 3]}>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Category:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.category}
					</Text>
				</Box>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Company:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.company}
					</Text>
				</Box>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Instructors:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.author}
					</Text>
				</Box>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Course Length:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.courseLength}
					</Text>
				</Box>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Course Materials:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.courseMaterials}
					</Text>
				</Box>
				<Box
					p={2}
					bg="muted"
					sx={{
						color: "secondary",
						fontWeight: "700",
						fontSize: "12px",
					}}
				>
					Number of Modules:
					<br />
					<Text
						sx={{
							color: "text",
							fontWeight: "400",
						}}
					>
						{post.frontmatter.modules.length}
					</Text>
				</Box>
			</Grid>
			<Divider
				sx={{
					backgroundColor: "primary",
					marginBottom: "60px",
				}}
			/>
			<p>{post.frontmatter.excerpt}</p>
			<Grid columns={[1, 1, 3]}>
				{post.frontmatter.modules.map((module) => {
					return (
						<Card
							key={post.frontmatter.moduleID}
							sx={{
								maxWidth: "100%",
								padding: 1,
								borderRadius: 2,
								border: "1px solid",
								borderColor: "primary",
							}}
						>
							<Link
								to={`/myame/course/${post.frontmatter.courseID}/module/${module.moduleID}`}
								as={GatsbyLink}
							>
								<StaticImage src="../images/thumbnail.png" />
							</Link>
							<Text
								as="p"
								px={2}
								py={1}
								sx={{ color: "primary", fontWeight: "bold", fontSize: "12px" }}
							>
								Module No. {module.moduleNumber} - {module.moduleID}
							</Text>
							<Text
								as="h3"
								p={2}
								sx={{
									color: "secondary",
								}}
							>
								{module.title}
							</Text>

							<Text
								as="p"
								p={2}
								sx={{
									color: "text",
								}}
							>
								{module.content}
							</Text>
							<Link
								p={2}
								to={`/myame/course/${post.frontmatter.courseID}/module/${module.moduleID}`}
								as={GatsbyLink}
							>
								<Button my={3}>Start Module</Button>
							</Link>
						</Card>
					);
				})}
			</Grid>
		</Container>
	);
};

const Module = ({ user, courseID, moduleID, posts }) => {
	const organization = user["https://enterprise.ame.systems/org_name"];

	const submitURL = "/myame/course/" + courseID;
	const [state, setState] = React.useState({
		uid: user.email,
		org: organization,
		course: courseID,
		module: moduleID,
	});

	//new variables
	const [answers, setAnswers] = React.useState([]);
	const [moduleNumberRate, setModuleNumberRate] = React.useState(0);
	const [moduleNameRate, setModuleNameRate] = React.useState("");

	useEffect(() => {
		console.log("rate Number", moduleNumberRate);
		console.log("rate Name", moduleNameRate);
		console.log(answers);
	}, [answers, moduleNameRate, moduleNumberRate]);

	const handleChange = (e) => {
		const id = e.target.name;
		const answer = { id, answer: e.target.value };
		let answersArray = [];
		if (answers.some((answer) => answer.id === id)) {
			answersArray = [...answers.filter((answer) => answer.id !== id), answer];
			setAnswers(answersArray);
		} else {
			answersArray = [...answers, answer];
			setAnswers(answersArray);
		}
	};

	const handleModuleChange = (event) => {
		setModuleNumberRate(event.target.value);
		const moduleRateByName = [
			"Very poor",
			"poor",
			"acceptable",
			"Good",
			"Very Good",
		];

		for (let index = 0; index < moduleRateByName.length; index++) {
			if (index + 1 == event.target.value)
				return setModuleNameRate(moduleRateByName[index]);
		}
	};

	const sendDataToAPI = (e) => {
		let score = 0;
		for (let i = 0; i < answers.length; i++) {
			if (answers[i].answer === "correct") {
				score++;
			}
		}

		const data = {
			score: score,
			quizName: moduleID,
			organization: "SDT",
			studentName: user.name,
			rate: moduleNameRate,
		};

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		};

		fetch(
			"https://sdt-ame-api-cme2y25n4q-ue.a.run.app/api/ame/quizResults",
			requestOptions
		)
			.then((response) => {
				response.json();
			})
			.then((res) => {
				navigate("/myame");
				console.log(res);
			});
	};

	let displayCourseTitle = {};
	let displayModuleTitle = {};
	let displayModuleExcerpt = {};
	let displayModuleVideo = {};
	let displayModuleQuiz = {};

	{
		posts.map((post) => {
			if (post.frontmatter.courseID === courseID) {
				displayCourseTitle = (
					<h1>
						{post.frontmatter.title} - {post.frontmatter.subTitle}
					</h1>
				);
				{
					post.frontmatter.modules.map((module) => {
						if (module.moduleID === moduleID) {
							displayModuleTitle = (
								<h2>
									Module {module.moduleNumber} - {module.moduleID} -{" "}
									{module.title}
								</h2>
							);
							displayModuleExcerpt = <p>{module.content}</p>;
							displayModuleVideo = (
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										src={module.videoLink}
										frameborder="0"
										allow="autoplay; fullscreen; picture-in-picture"
										allowfullscreen
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										title={moduleID}
									></iframe>
								</div>
							);

							displayModuleQuiz = (
								<Box
									py={4}
									as="form"
									name="ame-module-quiz"
									method="post"
									action={submitURL}
									// data-netlify="true"
									// data-netlify-honeypot="bot-field"
									onSubmit={sendDataToAPI}
								>
									<h2>{module.title} - Quiz</h2>
									<Divider
										sx={{
											backgroundColor: "primary",
											marginBottom: "40px",
										}}
									/>
									<Box
										p={2}
										sx={{
											backgroundColor: "muted",
										}}
									>
										<Text
											py={2}
											as="h3"
											sx={{
												color: "secondary",
											}}
										>
											Rate This Module
										</Text>
										<Grid columns={[1, 1, 5]}>
											<Label>
												<Radio
													name="rating"
													value={1}
													// onChange={handleChange}
													// required
												/>
												Very Poor
											</Label>
											<Label>
												<Radio
													name="rating"
													value={2}
													onChange={handleModuleChange}
													// required
												/>
												Poor
											</Label>
											<Label>
												<Radio
													name="rating"
													value={3}
													onChange={handleModuleChange}
													// required
												/>
												Acceptable
											</Label>
											<Label>
												<Radio
													name="rating"
													value={4}
													onChange={handleModuleChange}
													// required
												/>
												Good
											</Label>
											<Label>
												<Radio
													name="rating"
													value={5}
													onChange={handleModuleChange}
													// required
												/>
												Very Good
											</Label>
										</Grid>
									</Box>

									<Input
										sx={{ opacity: "0", maxHeight: "1px" }}
										type="text"
										name="uid"
										id="uid"
										value={user.email}
										// onChange={handleChange}
										// required
									/>
									<Input
										sx={{ opacity: "0", maxHeight: "1px" }}
										type="text"
										name="org"
										id="org"
										value={organization}
										// onChange={handleChange}
										// required
									/>
									<Input
										sx={{ opacity: "0", maxHeight: "1px" }}
										type="text"
										name="course"
										id="course"
										value={courseID}
										// onChange={handleChange}
										// required
									/>
									<Input
										sx={{ opacity: "0", maxHeight: "1px" }}
										type="text"
										name="module"
										id="module"
										value={moduleID}
										// onChange={handleChange}
										// required
									/>
									<Input
										type="hidden"
										name="form-name"
										value="ame-module-quiz"
									/>
									<Grid columns={[1, 1, 2]}>
										{module.quiz.map((question) => {
											return (
												<Box
													p={2}
													sx={{
														backgroundColor: "muted",
													}}
												>
													<Text
														py={2}
														as="h3"
														sx={{
															color: "secondary",
														}}
													>
														{question.question}
													</Text>
													{question.choices.map((answer) => {
														return (
															<Label>
																<Radio
																	name={question.qnumber}
																	value={
																		answer === question.correctAnswer
																			? "correct"
																			: "incorrect"
																	}
																	onChange={handleChange}
																	required
																/>
																{answer}
															</Label>
														);
													})}
												</Box>
											);
										})}
									</Grid>

									<Button
										my={3}
										as="button"
										type="submit"
										// onClick={sendDataToAPI}
									>
										Submit Quiz
									</Button>
								</Box>
							);
						}
					});
				}
			}
		});
	}
	return (
		<Container>
			{displayCourseTitle}
			{displayModuleTitle}
			{displayModuleExcerpt}
			{displayModuleVideo}
			{displayModuleQuiz}
		</Container>
	);
};

const Myame = ({ data }) => {
	const [isvalid, setIsValid] = React.useState(false);
	//check if user is authenticated
	const posts = data.allMdx.nodes;

	//check if current user has a valid subscription
	useEffect(() => {
		window.addEventListener("beforeunload", (ev) => {
			localStorage.setItem("isLoggedIn", false);
			logout();
		});
		console.log("fixing session");
		verifyUserSubscription();
		//check if user is authenticated
	}, []);

	//check if user is authenticated
	if (!isAuthenticated()) {
		login();
		return <p>Redirecting to login...</p>;
	}

	//retrieves the data from the user
	const user = getProfile();
	const organization = user["https://ame.systems/org_name"];

	//intanciate stripe
	let stripePromise;

	//this function will in charge of checking users subscription
	const verifyUserSubscription = async () => {
		//retrieve the user ID
		const userID = user.sub;

		//options for the GET request to Auht0 API
		const options = {
			method: "GET",
			url: `https://ame-systems.us.auth0.com/api/v2/users/${userID}`,
			headers: {
				authorization: `Bearer ${process.env.AUTH0_APIV2KEY}`,
			},
		};

		//send the data to retieve user information
		await axios
			.request(options)
			.then((response) => {
				const today = new Date();
				//check if user has a valid subscription
				if (
					!response.data.user_metadata ||
					!response.data.user_metadata.validToken ||
					!response.data.user_metadata.subs_expire_date ||
					response.data.user_metadata.validToken === false
				)
					return navigate("/");

				//Parse the expiration date of user to compare dates and determine if user still has a valid subscription
				const subscriptionExpirationDate = new Date(
					Date.parse(response.data.user_metadata.subs_expire_date)
				);

				if (subscriptionExpirationDate.getTime() < today.getTime())
					return navigate("/");

				setIsValid(true);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	//get Stripe
	const getStripe = () => {
		if (!stripePromise) {
			stripePromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY_LIVE}`);
		}
		return stripePromise;
	};

	//Stripe checkout option
	const redirectToCheckout = async () => {
		const stripe = await getStripe();
		const { error } = await stripe.redirectToCheckout({
			// mode: "payment",
			mode: "subscription",
			lineItems: [
				{
					price: `${process.env.STRIPE_ITEM_SUBSCRIPTION_LIVE_1_YEAR}`,
					quantity: 1,
				},
			],
			successUrl: `${process.env.STRIPE_SUCCESS_URL}`,
			cancelUrl: `${process.env.STRIPE_CANCEL_URL}`,
		});
		if (error) {
			console.warn("Error:", error);
		}
	};

	if (isvalid) {
		return (
			<>
				<Seo />
				<NavBar />

				<Router>
					<Home path="/myame/" posts={posts} user={user} />
					<Module
						path="/myame/course/:courseID/module/:moduleID"
						user={user}
						posts={posts}
					/>
					{posts.map((post) => {
						const modules = post.frontmatter.modules;

						console.log(modules);
						return (
							<Course
								path={`/myame/course/${post.frontmatter.courseID}`}
								courseID={post.frontmatter.courseID}
								courseTitle={post.frontmatter.title}
								courseSubTitle={post.frontmatter.subTitle}
								courseFeatureImage={post.featuredImage}
								courseExcerpt={post.frontmatter.excerpt}
								courseModules={post.frontmatter.modules}
								post={post}
							/>
						);
					})}
				</Router>
			</>
		);
	} else {
		return (
			<div className="spinner">
				<Spinner sx={{ size: 90 }} />
			</div>
		);
	}
};

export default Myame;

export const pageQuery = graphql`
	query {
		allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				id
				slug
				excerpt(pruneLength: 250)
				frontmatter {
					category
					excerpt
					author
					company
					courseID
					courseMaterials
					courseLength
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								width: 1200
								placeholder: BLURRED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
					title
					subTitle
					date(formatString: "YYYY MMMM Do")
					modules {
						moduleID
						moduleNumber
						title
						content
						videoLink
						quiz {
							question
							qnumber
							choices
							correctAnswer
						}
					}
				}
			}
		}
	}
`;
